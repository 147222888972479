import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import errorImg from "./../assets/images/error-img.png";

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="my-5 pt-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center my-5">
                  <h1 className="font-weight-bold text-error">
                    4{" "}
                    <span className="error-text">
                      0<img src={errorImg} alt="" className="error-img" />
                    </span>{" "}
                    4
                  </h1>
                  <h3 className="text-uppercase">Sorry, page not found</h3>
                  <div className="mt-5 text-center">
                    {/* <Link
                      to="/"
                      className="btn btn-primary waves-effect waves-light pt-1"
                    >
                      Back to Dashboard
                    </Link> */}
                    <button
                      onClick={() => this.props.history.goBack()}
                      className="btn btn-info ml-5 pt-1"
                    >
                      <span className="glyphicon glyphicon-envelope"></span>
                      Go Back
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                style={{
                  padding: "40px 15px",
                  textAlign: "center",
                }}
              >
                <h1>Oops!</h1>
                <h2>404 Not Found</h2>
                <div className="error-details">
                  Sorry, an error has occured, Requested page not found!
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <Link to="/dashboard" className="btn btn-primary btn-lg">
                    <span className="glyphicon glyphicon-home"></span>
                    Take Me Home
                  </Link>
                  <button
                    onClick={() => this.props.history.goBack()}
                    className="btn btn-info btn-lg ml-5"
                  >
                    <span className="glyphicon glyphicon-envelope"></span>
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

export default NotFound;
