import Http from "./../../../utils/Http";
// import moment from "moment-timezone";

const apiBase = "/api/v1/enterprise/tasks/";

export function createNewTask(params) {
  return Http.post(apiBase + "add-task", params);
}

export function updateTask(params) {
  return Http.post(apiBase + "edit-task", params);
}

export function getTaskList(params) {
  let url = apiBase + "get-list-of-tasks";
  let { tasktype = "", search = "", catSearch = "" } = params;
  let _getParams = [];
  if (tasktype != "") {
    _getParams.push(`tasktype=${tasktype}`);
  }
  _getParams.push(`search=${search}`);
  _getParams.push(`catSearch=${catSearch}`);
  _getParams.push(`viewArchive=${params.viewArchive}`);
  _getParams.push(`viewallread=${params.viewallread}`);
  _getParams.push(`viewallpronunciation=${params.viewallpronunciation}`);
  _getParams.push(`viewallvideo=${params.viewallvideo}`);
  _getParams.push(`viewallquestions=${params.viewallquestions}`);
  url = url + "?" + _getParams.join("&");
  return Http.get(url);
}

export function getMyTaskList(params) {
  let url = apiBase + "get-my-tasks";
  let { tasktype = "", search = "", catSearch = "" } = params;
  let _getParams = [];
  if (tasktype != "") {
    _getParams.push(`tasktype=${tasktype}`);
  }
  _getParams.push(`search=${search}`);
  _getParams.push(`catSearch=${catSearch}`);
  // _getParams.push(`viewallread=${params.viewallread}`);
  // _getParams.push(`viewallpronunciation=${params.viewallpronunciation}`);
  _getParams.push(`viewallvideo=${params.viewallvideo}`);
  url = url + "?" + _getParams.join("&");
  return Http.get(url);
}

export function getContentLibraryListForTask(params) {
  let url = "/api/v1/enterprise/contentlibrary/get-video-content-for-task";
  let { contenttype = "", search = "", catSearch = "" } = params;
  let _getParams = [];
  if (contenttype != "") {
    _getParams.push(`contenttype=${contenttype}`);
  }
  _getParams.push(`search=${search}`);
  _getParams.push(`catSearch=${catSearch}`);

  url = url + "?" + _getParams.join("&");
  return Http.get(url);
}

export function getreadTypeTextForImage(params) {
  return Http.post("api/v1/public/readTypeUpload", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function assignTaskToUsers(params) {
  return Http.post(apiBase + "assign-task-users", params);
}

export function getMembersDataForTask(params) {
  return Http.post(apiBase + "get-members-task-data-taskid", params);
}

export function markTaskAsCompletedReq(params) {
  return Http.post(apiBase + "update-my-task", params);
}

export function searchTask(params) {
  return Http.post(apiBase + "search-task", params);
}

export function getUserTask(params) {
  return Http.post(apiBase + "get-my-task", params);
}

export function getAssignedMembersListForTask(params) {
  return Http.post(apiBase + "get-members-by-taskid", params);
}

export function markAsReviewedTask(params) {
  return Http.post(apiBase + "mark-task-reviewed", params);
}

export function deleteTask(params) {
  return Http.post(apiBase + "delete-task", params);
}
