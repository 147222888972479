import Http from "./../../utils/BackendHttp";
import { adminauthLogout, adminauthUser } from "./../../store/reducers/auth";
import { notifyError } from "./../../utils/Helper";

const apiBase = "/api/v1/admin/";

/**
 * login user
 *
 * @param credentials
 * @returns {function(*)}
 */
export function login(credentials) {
  return Http.post("admin/login", credentials);
}

export function verifyEmail(params) {
  return Http.post("admin/verify-email", params);
}

export function adminlogout() {
  return (dispatch) => {
    dispatch(adminauthLogout());
    // return Http.delete("auth/logout")
    //   .then(() => {
    //     dispatch(authLogout());
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
}

export function fetchAdminUser() {
  return (dispatch) => {
    return Http.get(apiBase + "general/auth/me")
      .then((res) => {
        const data = res.data.response;
        dispatch(adminauthUser(data));
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          const { data } = err.response;
          notifyError(data.message);
          dispatch(adminauthLogout());
        }
      });
  };
}

export function updateAdminProfile(params) {
  return Http.post(apiBase + "general/auth/updateprofile", params);
}

export function forgotAdminPasswordEmail(params) {
  return Http.post("admin/forgot-password", params);
}

export function resetAdminPassword(params) {
  return Http.post("admin/reset-password", params);
}

export function changeAdminPassword(params) {
  return Http.post(apiBase + "general/auth/changepassword", params);
}

export function updateProfileImageRequest(params) {
  return Http.post(apiBase + "general/auth/updateprofileimage", params);
}

export function putSignedUrlS3(params) {
  return Http.post(
    apiBase + "general/put-signed-request-for-upload-to-s3",
    params
  );
}

export function syncFirebaseDatabase() {
  return Http.post(apiBase + "general/sync-firebase-data");
}

export function getCoachesList(params) {
  return Http.post(apiBase + "users/get-coachs", params);
}

export function getEnterprises(params) {
  let { url = apiBase + "enterprise/get-enterprise-list", searchInput = "" } =
    params;
  let _getParams = [];
  if (searchInput != "") {
    _getParams.push(`searchInput=${searchInput}`);
  }
  url = url + "?" + _getParams.join("&");
  return Http.get(url);
}

export function getAdminDashboardData() {
  return Http.get(apiBase + "general/my-dashboard");
}
