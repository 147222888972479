import React, { Component } from "react";

import { connect } from "react-redux";
import { Button } from "reactstrap";

import { Link } from "react-router-dom";

import ProfileMenu from "./ProfileMenu";

//Import logo Images
import logosm from "./../../../assets/images/logo.png";
import logo from "./../../../assets/images/logo.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    // console.log("Cliking!");
    this.props.toggleMenuCallback();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/admin/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logosm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="50px" />
                  </span>
                </Link>
              </div>

              {this.props.showMenuToggle && (
                <Button
                  size="sm"
                  color="none"
                  type="button"
                  onClick={this.toggleMenu}
                  className="px-3 font-size-24 header-item waves-effect"
                  id="vertical-menu-btn"
                >
                  <i className="ri-menu-2-line align-middle"></i>
                </Button>
              )}
            </div>

            <div className="d-flex">
              {this.props.showMenuToggle ? (
                <div className="dropdown d-none ml-1">
                  <Button
                    color="none"
                    type="button"
                    className="header-item noti-icon waves-effect"
                    onClick={this.toggleFullscreen}
                  >
                    <i className="ri-fullscreen-line"></i>
                  </Button>
                </div>
              ) : (
                <Link
                  className="waves-effect waves-light mr-1 btn btn-outline-primary pt-1 h-100 mt-3 font-weight-bold"
                  to="/admin/dashboard"
                >
                  Go to Dashboard
                </Link>
              )}

              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default connect()(Header);
