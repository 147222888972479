import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { fetchUser } from "./../enterpriseServices";
import { changeSidebarType } from "./../../../store/reducers/generalActions";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    };
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isPreloader === true) {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";

        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      } else {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }
    }
  }

  componentDidMount() {
    document.getElementById("preloader").style.display = "none";
    document.getElementById("status").style.display = "none";
    // Scroll Top to 0
    window.scrollTo(0, 0);
    // let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    // document.title = currentage + " | GET MEE";
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme);
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
    if (this.props.isAuthenticated && this.props.user.name == undefined) {
      this.props.dispatch(fetchUser());
    }
  }

  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.dispatch(changeSidebarType("condensed", this.state.isMobile));
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.dispatch(changeSidebarType("default", this.state.isMobile));
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="preloader" style={{ display: "none" }}>
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>

        <div id="layout-wrapper">
          <Header
            toggleMenuCallback={this.toggleMenuCallback}
            showMenuToggle={true}
          />
          <Sidebar
            theme={this.props.leftSideBarTheme}
            isMobile={this.state.isMobile}
            type={this.props.leftSideBarType}
          />
          <div className="main-content">
            {this.props.children}
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    leftSideBarType: state.generalActions.leftSideBarType,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};
export default connect(mapStatetoProps)(withRouter(Layout));
