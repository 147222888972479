import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import routes from "./routes";
import PrivateRoute from "./routes/Private";
import PublicRoute from "./routes/Public";

import NotFound from "./components/notFound";

// Import scss
import "./theme.scss";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          {routes.map((route, i) => {
            if (route.auth) {
              return <PrivateRoute key={i} {...route} />;
            }
            return <PublicRoute key={i} {...route} />;
          })}
          <Route path="/404" component={NotFound} />
          <Redirect path="/" exact to="/enterprise/dashboard" />
          <Redirect to="/404" />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
