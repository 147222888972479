// import lib
import { lazy } from "react";

const webRoutes = [
  {
    path: "/activation-required",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "activation" */ "./../components/activation")
    ),
  },
  {
    path: "/register",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "register" */ "./../components/register")
    ),
  },
];

export default webRoutes;
