import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import { adminlogout } from "./../adminServices";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  handleProfileRedirect = (e) => {
    e.preventDefault();
    this.props.history.replace("/admin/profile");
  };

  handleLogout = (e) => {
    e.preventDefault();

    this.props.dispatch(adminlogout());
  };

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <img
              className="rounded-circle header-profile-user mr-1"
              src={this.props.adminUser.avatarUrl}
              alt={this.props.adminUser.name}
            />
            <span className="d-none d-xl-inline-block ml-1 text-transform">
              {this.props.adminUser.name}
            </span>
            <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-lg" right>
            <div className="px-lg-2">
              <Row className="no-gutters">
                <Col xl={12} sm={12}>
                  <Row>
                    <Col xs={4}>
                      <img
                        src={this.props.adminUser.avatarUrl}
                        alt={this.props.adminUser.name}
                        className="avatar-lg mt-2"
                      />
                    </Col>
                    <Col xs={8} className="mt-3">
                      <h5>{this.props.adminUser.name}</h5>
                      <p className="text-muted mt-2">
                        <strong>{this.props.adminUser.role}</strong>
                        <br />
                        {this.props.adminUser.email}
                      </p>
                      <button
                        onClick={this.handleProfileRedirect}
                        className="btn btn-primary btn-sm"
                      >
                        View Profile
                      </button>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  {/* <DropdownItem className="d-block" href="#">
                    Enterprise Plan
                  </DropdownItem> */}
                  <DropdownItem
                    className="d-block"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Privacy Policy
                  </DropdownItem>
                  <DropdownItem
                    className="d-block"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Terms & Condition
                  </DropdownItem>
                  <DropdownItem
                    className="mt-2 text-danger text-center"
                    href="#"
                    onClick={this.handleLogout}
                  >
                    <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
                    Logout
                  </DropdownItem>
                </Col>
              </Row>
            </div>
          </DropdownMenu>
          {/* <DropdownMenu right>
            <DropdownItem href="#" onClick={this.handleProfileRedirect}>
              <i className="ri-user-line align-middle mr-1"></i> Profile
            </DropdownItem>
            <DropdownItem className="d-block" href="#">
              <span className="badge badge-success float-right mt-1">11</span>
              <i className="ri-settings-2-line align-middle mr-1"></i> Settings
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              className="text-danger"
              href="#"
              onClick={this.handleLogout}
            >
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
              Logout
            </DropdownItem>
          </DropdownMenu> */}
        </Dropdown>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAdminAuthenticated: state.auth.isAdminAuthenticated,
    adminUser: state.auth.adminUser,
  };
};

export default withRouter(connect(mapStateToProps)(ProfileMenu));
// export default ProfileMenu;
